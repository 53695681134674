<template>
  <button type="button" class="filter-btn" @click="$emit('click', $event)">
    <img src="../assets/svgs/icons/sliders.svg" alt="filter ikon" />
    <p>Filtrera {{ activeFilters ? `(${activeFilters})` : '' }}</p>
  </button>
</template>

<script>
export default {
  props: {
    activeFilters: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style scoped>
.filter-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 2rem;
  outline: none;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 50px;
  padding: 0.8rem 1.7rem;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  color: var(--color-dark-blue);
  background-color: #fff;
  margin-right: auto;
}
.filter-btn:active {
  outline: none;
}
</style>
