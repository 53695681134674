<template>
  <DefaultPage>
    <FilterMain
      v-model="filterQuery"
      :value="filterQuery"
      @input="debouncedSearch"
      @clear="clearInputSearch"
    >
      <FilterBtn
        type="button"
        class="filter-btn"
        :active-filters="activeFilters"
        @click="filterModalVisible = true"
      />
    </FilterMain>

    <div class="applications-view-wrapper">
      <div v-if="isLoadingApps" class="loading-wrapper">
        <BaseLoadingSpinner :with-overlay="false" :size="60" />
      </div>
      <template v-else>
        <section class="applications-wrapper">
          <SubletApplicationsLane
            title="Inkommande"
            status="new"
            :applications="applicationsNew"
            :container-style="{
              opacity: 0.5
            }"
          ></SubletApplicationsLane>

          <SubletApplicationsLane
            title="Fatta beslut"
            status="waiting"
            :applications="applicationsWaiting"
          ></SubletApplicationsLane>

          <SubletApplicationsLane
            title="Pågående"
            status="ongoing"
            :applications="applicationsOngoing"
          ></SubletApplicationsLane>
        </section>
      </template>
      <router-view />
      <FilterModal
        v-if="filterModalVisible"
        :apps="filteredAhApps"
        :show-swap-filter="false"
        @close="filterModalVisible = false"
      />
    </div>
  </DefaultPage>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import debounce from 'lodash.debounce';

import statusTypes from '@/utils/statusTypes';
import SubletApplicationsLane from '@/components/SubletApplicationsLane.vue';
import FilterMain from '@/components/FilterMain.vue';
import FilterModal from '@/components/FilterModal.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import DefaultPage from '@/components/DefaultPage.vue';
import FilterBtn from '@/components/FilterBtn.vue';

export default {
  name: 'SubletApplicationsView',
  components: {
    SubletApplicationsLane,
    FilterMain,
    BaseLoadingSpinner,
    FilterModal,
    DefaultPage,
    FilterBtn
  },
  beforeRouteUpdate(to, from, next) {
    this.maybeClearInputSearch(to.path);
    next();
  },
  beforeRouteLeave(to, from, next) {
    //Leaving to new route and unmounting
    this.maybeClearInputSearch(to.path);
    next();
  },
  data() {
    return {
      filterQuery: '',
      filterModalVisible: false
    };
  },
  computed: {
    ...mapGetters({
      filteredAhApps: 'applications/filteredAhApps',
      tooltips: 'app/tooltips',
      activeFilters: 'applications/activeFilters'
    }),
    ...mapState('applications', ['isLoadingApps']),
    ...mapState({
      activeApp: state => state.app.activeApp
    }),
    applicationsNew() {
      if (!this.filteredAhApps) {
        return [];
      }
      return this.filteredAhApps.filter(
        x => x.landlordStatus === statusTypes.INCOMING
      );
    },
    applicationsWaiting() {
      if (!this.filteredAhApps) {
        return [];
      }
      return this.filteredAhApps.filter(
        x => x.landlordStatus === statusTypes.READY
      );
    },
    applicationsOngoing() {
      if (!this.filteredAhApps) {
        return [];
      }
      return this.filteredAhApps.filter(
        x =>
          x.landlordStatus === statusTypes.ONGOING ||
          x.landlordStatus === statusTypes.APPROVED
      );
    }
  },
  beforeDestroy() {
    this.clearFilter();
  },

  async created() {
    this.debouncedSearch = debounce(this.search, 300);
    await this.fetchLandlordUsersWithUnassigned();
    await this.fetchSubletApps();
  },

  methods: {
    ...mapActions({
      fetchSubletApps: 'applications/fetchSubletApps',
      fetchLandlordUsersWithUnassigned:
        'applications/fetchLandlordUsersWithUnassigned',
      clearFilter: 'applications/clearFilter',
      updateActiveApp: 'app/updateActiveApp'
    }),
    ...mapMutations({
      setFilter: 'applications/setFilter',
      resetFirstPartOfTooltipsArray: 'app/resetFirstPartOfTooltipsArray'
    }),
    maybeClearInputSearch(path) {
      if (!path.includes('andrahand/alla')) {
        this.clearInputSearch();
      }
    },
    clearInputSearch() {
      this.filterQuery = '';
      this.debouncedSearch.cancel();
      this.search('');
    },
    search(query) {
      this.setFilter(query);
    }
  }
};
</script>

<style scoped>
.applications-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  max-width: 1080px;
}

.applications-view-wrapper {
  flex: 1;
  margin-left: 20px;
}

.loading-wrapper {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}
</style>
