import { render, staticRenderFns } from "./RejectReasonModal.vue?vue&type=template&id=1e58a83a&scoped=true"
import script from "./RejectReasonModal.vue?vue&type=script&lang=js"
export * from "./RejectReasonModal.vue?vue&type=script&lang=js"
import style0 from "./RejectReasonModal.vue?vue&type=style&index=0&id=1e58a83a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e58a83a",
  null
  
)

export default component.exports