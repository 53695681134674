<template>
  <ApplicationCard
    :type="reasonTypeTitle"
    :to="getPath"
    :status="application.status"
    :subtitle="subtitle"
    :subtitle-class="subtitleClass"
    :is-new="isNew"
    :full-name="fullName"
    :last-name="lastName"
    :status-date="statusDate"
    :unread-notes="application?.unreadNotes"
    :closed-statuses="closedStatuses"
    :application-id="`${application.id}`"
    :application-tags="application.tags"
    :show-tag-button="true"
    :is-overtime="daysLeftOvertime"
  >
    <template slot="body">
      <div class="application-card-info-container">
        <div class="application-card-info">
          <p>
            <span class="capitalize-first">{{ currentTenantsNameGroup }}</span>
            <span class="light">{{ application.apartment?.address }}</span>
          </p>
          <p v-if="application.secondaryTenants[0]">
            <span class="capitalize-first">{{
              application.secondaryTenants[0].name
            }}</span>
            <span class="light">{{
              application.secondaryTenants[0].address
            }}</span>
          </p>
          <p v-if="application.secondaryTenants[1]">
            <span class="capitalize-first">{{
              application.secondaryTenants[1].name
            }}</span>
            <span class="light">{{
              application.secondaryTenants[1].address
            }}</span>
          </p>
          <p>Hyra: {{ application.apartment?.rent }} kr</p>
          <p>Lägenhetsnr: {{ application.apartment?.apartmentNumber }}</p>
          <p>
            Objektnr:
            {{ application.apartment?.objectNumber }}
          </p>
        </div>
      </div>
    </template>
    <template
      v-if="
        validDate(application.reason.rentFrom) &&
        validDate(application.reason.rentTo)
      "
      slot="footer"
    >
      <p v-if="['APPROVED', 'CLOSED'].includes(application.landlordStatus)">
        Uthyrningsperiod:
        <b
          >{{ new Date(application?.startDate).toLocaleDateString('sv-SE') }}
        </b>
        -
        <b>{{ new Date(application?.endDate).toLocaleDateString('sv-SE') }}</b>
        <template v-if="application.landlordStatus === 'APPROVED'">
          <br />
          <span
            class="days-left"
            :class="{
              overtime: daysLeftOvertime
            }"
            >(<strong>{{ daysLeftStr }}</strong
            >{{ daysLeftMsg }})</span
          >
        </template>
      </p>
      <p v-else>
        Önskar hyra ut:
        <b
          >{{
            new Date(application?.reason.rentFrom).toLocaleDateString('sv-SE')
          }}
        </b>
        -
        <b>
          {{
            new Date(application?.reason.rentTo).toLocaleDateString('sv-SE')
          }}</b
        >
      </p>
    </template>
  </ApplicationCard>
</template>

<script>
import ApplicationCard from '@/components/ApplicationCard.vue';
import validDate from '@/utils/validDate';

export default {
  name: 'SubletApplicationCard',
  components: {
    ApplicationCard
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    subtitleClass: {
      type: String,
      default: ''
    },
    isNew: {
      type: Boolean,
      default: false
    },
    fullName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    },
    statusDate: {
      type: String,
      default: ''
    },
    closedStatuses: {
      type: Array,
      default: () => []
    },
    reasonTypeTitle: {
      type: String,
      required: true
    },
    toPath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      validDate
    };
  },
  computed: {
    getPath() {
      return `${this.toPath}/${this.application.id}`;
    },
    currentTenantsNameGroup() {
      const tenant1Name = this.application.currentTenants[0]?.name;
      const tenant2Name = this.application.currentTenants[1]?.name;

      if (tenant1Name && tenant2Name) {
        return `${tenant1Name} & ${tenant2Name}`;
      } else if (tenant1Name) {
        return tenant1Name;
      }
      return '';
    },
    daysLeftOvertime() {
      if (this.application.landlordStatus !== 'APPROVED') {
        return false;
      }
      return 0 >= this.daysLeft;
    },
    daysLeft() {
      const currentDate = new Date();
      const endDate = new Date(this.application.endDate);

      const timeDifference = endDate - currentDate;

      return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    },
    daysLeftMsg() {
      if (this.daysLeftOvertime) {
        return ' dagar efter avtalad period';
      }
      return this.daysLeft === 1 ? ' dag kvar' : ' dagar kvar';
    },
    daysLeftStr() {
      const posDaysLeft = Math.abs(this.daysLeft);
      switch (posDaysLeft) {
        case 1:
          return 'en';
        case 2:
          return 'två';
        case 3:
          return 'tre';
        case 4:
          return 'fyra';
        case 5:
          return 'fem';
        case 6:
          return 'sex';
        case 7:
          return 'sju';
        case 8:
          return 'åtta';
        case 9:
          return 'nio';
        default:
          return posDaysLeft;
      }
    }
  }
};
</script>

<style scoped>
.application-card-info-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 0.2rem;
  color: var(--color-dark-blue);
  font-size: clamp(1.2rem, 0.7vw, 1.4rem);
  font-weight: 600;
}
.application-card-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.light {
  color: #878b9d;
  font-size: clamp(1rem, 0.6vw, 1.2rem);
  font-weight: 600;
  margin-left: 0.2rem;
}

.days-left.overtime {
  color: var(--color-red);
}

.days-left strong {
  font-weight: 800;
}
</style>
