function reasonText(reason) {
  switch (reason.type) {
    case 'sambo':
      return 'Provsambo';
    case 'arbete':
      return reason.movingAbroad ? 'Arbete utomlands' : 'Arbete på annan ort';
    case 'studie':
      return reason.movingAbroad ? 'Studier utomlands' : 'Studier på annan ort';
    case 'other':
      return 'Annan anledning';
    case 'utlandsvistelse':
      return 'Utlandsvistelse';
    default:
      return '';
  }
}

export { reasonText };
