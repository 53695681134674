<template>
  <ErrorModal v-if="!isLoading && errorModalVisible" @close="$emit('close')" />
  <div v-else-if="isLoading" class="loading-wrapper">
    <BaseLoadingSpinner :with-overlay="false" />
  </div>
  <ApplicationContainerModal
    v-else
    v-esc-click="handleEscClick"
    v-on="$listeners"
  >
    <template slot="modals">
      <SubletNotesModal
        v-if="currentModal === 'NOTES'"
        :unread-notes="application.unreadNotes"
        :application-id="`${application.id}`"
        :read-only-mode="false"
        :notes="application.landlordNotes"
        @close="currentModal = null"
        @addNote="handleAddNote"
        @deleteNote="handleDeleteNote"
        @editNote="handleEditNote"
      />
      <SubletDocumentsModal
        v-if="currentModal === 'DOCUMENTS'"
        :application-id="`${application.id}`"
        :applicant-name="activeCurrentTenant.name"
        :files="application.files"
        :selected-type="selectedDocumentType"
        :selected-applicant-id="selectedApplicantId"
        :has-scrive-document="!!application.scriveId"
        @close="
          currentModal = null;
          selectedApplicantId = null;
          selectedDocumentType = null;
        "
      />

      <SubletApprovalModal
        v-if="currentModal === 'APPROVE'"
        :application-id="application.id"
        :initial-start-date="application.startDate"
        :initial-end-date="application.endDate"
        :rent-from="application.reason.rentFrom"
        :rent-to="application.reason.rentTo"
        @close="currentModal = null"
        @onApproved="leaveApplication"
      ></SubletApprovalModal>

      <SubletRejectModal
        v-if="currentModal === 'REJECT'"
        :application-id="application.id"
        @close="currentModal = null"
        @onRejected="leaveApplication"
      />

      <SubletPartInfoModal
        v-if="selectedPartInfo !== null"
        :position="selectedPartInfoModalPosition"
        :selected-info="selectedPartInfo"
        @close="selectedPartInfo = null"
      ></SubletPartInfoModal>

      <NotAssignedModal
        v-if="currentModal === 'USER_NOT_ASSIGNED'"
        @close="currentModal = null"
        @confirm="currentModal = 'REMOVE'"
      />

      <SubletRemoveModal
        v-if="currentModal === 'REMOVE'"
        :application-id="application.id"
        @close="currentModal = null"
        @onRemoved="leaveApplication"
      ></SubletRemoveModal>

      <SubletCloseModal
        v-if="currentModal === 'CLOSE'"
        :application-id="application.id"
        @close="currentModal = null"
        @cancelled="leaveApplication"
      ></SubletCloseModal>
    </template>
    <template slot="header">
      <TabHeader
        :all-disabled="isAnimatingModalSize"
        :title="tabHeaderTitle"
        :subtitle="tabHeaderSubtitle"
        :application-status="application.landlordStatus"
        :show-help-button="false"
        :tabs="[
          {
            title: 'Kontraktsinnehavare',
            active: activeTab === 1,
            tabLink: 1
          },
          {
            title: 'Andrahandshyresgäst',
            active: activeTab === 2,
            tabLink: 2
          }
        ]"
        @tabClick="setTab"
      />
    </template>
    <template slot="content">
      <template v-if="activeTab === 1">
        <ApplicationsRow two-columns>
          <ApplicationCard>
            <template slot="top-content">
              <PersonCard
                v-for="(currentTenant, i) in currentTenants"
                :key="currentTenant.name"
                :name="currentTenant.name"
                :address="getApartmentAdress"
                :current-index="i"
              >
                <BaseImage
                  :url="svgs.ICONS.CONTACT"
                  class="icon"
                  @click="
                    e => {
                      const x = e.clientX;
                      const y = e.clientY + 20;
                      openPartInfoModal({
                        x,
                        y,
                        type: 'currentTenants',
                        index: currentTenant.index
                      });
                    }
                  "
                ></BaseImage>
              </PersonCard>
            </template>
            <h4 v-if="application.landlordStatus !== 'INCOMING'">
              {{ application.apartment.address }},
              {{ application.apartment.postalCode }}
              {{ application.apartment.city }}
            </h4>
            <ul class="apartment-info">
              <li><b>Hyra:</b> {{ application.apartment.rent }} kr</li>
              <li>
                <b>Lägenhetsnr.:</b> {{ application.apartment.apartmentNumber }}
              </li>
              <li>
                <b>Objektnr.:</b> {{ application.apartment.objectNumber }}
              </li>
            </ul>
            <hr class="divider" />
            <SubletApplicationCheckboxes
              :files="application.files"
              :reason="application.reason"
              :application="application"
              :application-id="application.id"
              :checked-files="application.checkedFiles"
              :other-checkboxes="otherCheckboxes"
              :application-status="application.landlordStatus"
              @openFile="openDocumentModal($event)"
              @checksChange="updateCheckedMember($event)"
            />
            <br />
            <div class="card-content">
              <h4>Skäl</h4>
              <p>{{ reasonText }}</p>
            </div>
          </ApplicationCard>
          <ApplicationCard>
            <template v-if="application.reason.type === 'other'">
              <h4>Boendeort under uthyrningstiden</h4>
              <p>{{ application.reason.city }}</p>
              <hr class="divider" />
              <h4>Anledning</h4>
              <p>{{ application.reason.text }}</p>
            </template>
            <template v-if="application.reason.type === 'utlandsvistelse'">
              <h4>Boendeort under uthyrningstiden</h4>
              <p>{{ application.reason.city }}</p>
              <template v-if="application.reason.text">
                <hr class="divider" />
                <h4>Övrig information</h4>
                <p>{{ application.reason.text }}</p>
              </template>
            </template>
            <template
              v-if="
                application.reason.type === 'arbete' ||
                application.reason.type === 'studie'
              "
            >
              <h4>Boendeort under uthyrningstiden</h4>
              <p>{{ application.reason.city }}</p>
            </template>
            <template v-else-if="application.reason.type === 'sambo'">
              <h4>Adress under uthyrningstiden</h4>
              <p>
                {{ application.reason.address }},
                {{ application.reason.postalCode }}
                {{ application.reason.city }}
              </p>
              <hr class="divider" />
              <h4>Boendeform</h4>
              <ul class="apartment-info">
                <li><b>Boendeform:</b> {{ housingTypeTitle }}</li>
                <li><b>Hyresvärd:</b> {{ application.reason.houseOwner }}</li>
                <li>
                  <b>Telefonnummer till hyresvärd:</b>
                  {{ application.reason.ownerPhone }}
                </li>
                <li>
                  <b>Email-adress till hyresvärd:</b>
                  {{ application.reason.ownerEmail }}
                </li>
              </ul>
              <hr class="divider" />
              <h4>Uppgifter på den du ska provbo med</h4>
              <ul class="apartment-info">
                <li><b>Namn:</b> {{ application.reason.partnerName }}</li>
                <li><b>Personnr.:</b> {{ application.reason.partnerSsn }}</li>
              </ul>
            </template>
          </ApplicationCard>
        </ApplicationsRow>
      </template>
      <!-- START ACTIVETAB 2 -->
      <template v-if="activeTab === 2">
        <ApplicationsRow two-columns>
          <ApplicationCard
            v-for="secondaryTenant in secondaryTenants"
            :key="secondaryTenant.name"
            flex
          >
            <template slot="top-content">
              <PersonCard
                :name="secondaryTenant.name"
                :address="secondaryTenant.address"
              >
                <BaseImage
                  :url="svgs.ICONS.CONTACT"
                  class="icon"
                  @click="
                    e => {
                      const x = e.clientX;
                      const y = e.clientY + 20;
                      openPartInfoModal({
                        x,
                        y,
                        type: 'secondaryTenants',
                        index: secondaryTenant.index
                      });
                    }
                  "
                />
              </PersonCard>
            </template>
            <h3 :key="secondaryTenant.name" class="name capitalize-first">
              {{ secondaryTenant.name }}
            </h3>
            <ul class="apartment-info">
              <li><b>Adress:</b> {{ secondaryTenant.address }}</li>
              <li>
                <b>Telefonnr.:</b>
                {{ secondaryTenant.phone }}
              </li>
              <li><b>Personnr.:</b> {{ secondaryTenant.ssn }}</li>
              <li><b>E-post:</b> {{ secondaryTenant.email }}</li>
            </ul>
          </ApplicationCard>
        </ApplicationsRow>
      </template>
      <!-- END ACTIVETAB 2 -->
    </template>
    <template slot="footer">
      <div class="left">
        <BaseItemsList
          :class="{
            'no-pointer-events': hasRemovalTypeStatus
          }"
          :items="landlordUsersWithUnAssign.filter(x => !x.inactivatedAt)"
          :show-contact="true"
          @click="assignLandlordUser($event)"
        >
          <BaseIconText
            v-if="!application.assignedLandlordUserId"
            :icon-url="svgs.ICONS.ADMINISTRATOR"
            size="larger"
            class="assign-button"
          >
            {{ hasRemovalTypeStatus ? 'Ej tilldelad' : 'Tilldela handläggare' }}
          </BaseIconText>

          <UserIcon
            v-else
            :first-name="application.assignedLandlordFirstName"
            :last-name="application.assignedLandlordLastName"
            :full-name-visible="true"
          ></UserIcon>
        </BaseItemsList>

        <div v-if="!isIncoming && !hasRemovalTypeStatus">
          <button
            class="simple-button notes-button"
            @click="currentModal = 'NOTES'"
          >
            <BaseIconText :icon-url="svgs.ICONS.NOTEBOOK" size="larger">
              <NotificationSymbol
                v-if="false"
                slot="icon"
                border-color="#f1f2f6"
              />Noteringar</BaseIconText
            >
          </button>
        </div>

        <button
          v-if="!isIncoming && !isRejected && !hasRemovalTypeStatus"
          class="simple-button files-button"
          @click="currentModal = 'DOCUMENTS'"
        >
          <BaseIconText :icon-url="svgs.ICONS.ATTACHMENT" size="larger"
            >Visa {{ application.files?.length || 0 }} filer
          </BaseIconText>
        </button>
      </div>
      <div class="right">
        <div
          v-if="!isIncoming && !isApproved && !isRejected && !isRemoved"
          class="popupModal-container mr-15"
        >
          <PopupModal
            v-if="popupModalVisible"
            :items="itemsInPopupModal"
            @close="popupModalVisible = false"
            @click="handlePopupClick"
          />
          <button
            class="simple-button notes-button"
            @click="popupModalVisible = !popupModalVisible"
          >
            <BaseIconText :icon-url="svgs.ICONS.MORE">Fler val</BaseIconText>
          </button>
        </div>
        <template v-if="application.landlordStatus === 'READY'">
          <BaseButton
            class="mr-15"
            :is-warning="true"
            :is-rounded="true"
            :icon="svgs.ICONS.ERROR_WHITE"
            @click="currentModal = 'REJECT'"
          >
            Neka ansökan
          </BaseButton>

          <BaseButton
            :is-rounded="true"
            :icon="svgs.ICONS.CHECK_WHITE"
            @click="currentModal = 'APPROVE'"
          >
            Godkänn ansökan
          </BaseButton>
        </template>
        <BaseButton
          v-else-if="application.landlordStatus === 'APPROVED'"
          :is-warning="true"
          :is-rounded="true"
          :icon="svgs.ICONS.ERROR_WHITE"
          @click="currentModal = 'CLOSE'"
        >
          Avsluta uthyrning
        </BaseButton>
      </div>
    </template>
  </ApplicationContainerModal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import svgs from '@/utils/icon-constants';
import { reasonText } from '@/utils/subletUtils';
import validDate from '@/utils/validDate';

import BaseIconText from '@/components/BaseIconText.vue';
import BaseButton from '@/components/BaseButton.vue';
import TabHeader from '@/components/TabHeader.vue';
import NotificationSymbol from '@/components/NotificationSymbol.vue';
import ErrorModal from '@/components/ErrorModal.vue';
import escClick from '@/directives/esc-click';
import PopupModal from '@/components/PopupModal.vue';
import SubletPartInfoModal from '@/components/SubletPartInfoModal.vue';
import ApplicationContainerModal from '@/components/ApplicationContainerModal.vue';
import BaseLoadingSpinner from '@/components/BaseLoadingSpinner.vue';
import SubletNotesModal from '@/components/SubletNotesModal.vue';
import SubletDocumentsModal from '@/components/SubletDocumentsModal.vue';
import SubletApprovalModal from '@/components/SubletApprovalModal.vue';
import SubletRejectModal from '@/components/SubletRejectModal.vue';
import SubletCloseModal from '@/components/SubletCloseModal.vue';
import statusTypes from '@/utils/statusTypes';
import BaseItemsList from '@/components/BaseItemsList.vue';
import UserIcon from '@/components/UserIcon.vue';
import BaseImage from '@/components/BaseImage.vue';
import NotAssignedModal from '@/components/NotAssignedModal.vue';
import SubletRemoveModal from '@/components/SubletRemoveModal.vue';
import SubletApplicationCheckboxes from '@/components/SubletApplicationCheckboxes.vue';
import ApplicationsRow from './ApplicationsRow.vue';
import ApplicationCard from './ApplicationCard.vue';
import PersonCard from './PersonCard.vue';

//import validDate from '@/utils/validDate';

export default {
  name: 'SubletApplicationView',
  directives: { escClick },
  components: {
    BaseIconText,
    BaseButton,
    SubletNotesModal,
    TabHeader,
    NotificationSymbol,
    ErrorModal,
    PopupModal,
    ApplicationContainerModal,
    BaseLoadingSpinner,
    SubletDocumentsModal,
    SubletApprovalModal,
    SubletRejectModal,
    BaseItemsList,
    UserIcon,
    SubletPartInfoModal,
    BaseImage,
    NotAssignedModal,
    SubletRemoveModal,
    SubletCloseModal,
    SubletApplicationCheckboxes,
    ApplicationsRow,
    ApplicationCard,
    PersonCard
  },
  data() {
    return {
      svgs,
      currentModal: '',
      application: null,
      isAnimatingModalSize: false,
      isLoading: false,
      errorModalVisible: false,
      popupModalVisible: false,
      itemsInPopupModal: [
        // {
        //   title: 'Avbryt bytet',
        //   icon: svgs.ICONS.CHECK_NOTE_GREY,
        //   content:
        //     'Om en hyresgäst inte längre vill gå vidare med bytet, så flyttas ärendet till avslutat men markeras som avbrytet.',
        //   cta: 'Vill du avbryta bytet?',
        //   type: 'ABORT'
        // },
        {
          title: 'Radera ansökan',
          icon: `<svg viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M7.88232 16C8.14754 16 8.40189 15.8946 8.58943 15.7071C8.77697 15.5196 8.88232 15.2652 8.88232 15V9C8.88232 8.73478 8.77697 8.48043 8.58943 8.29289C8.40189 8.10536 8.14754 8 7.88232 8C7.61711 8 7.36275 8.10536 7.17522 8.29289C6.98768 8.48043 6.88232 8.73478 6.88232 9V15C6.88232 15.2652 6.98768 15.5196 7.17522 15.7071C7.36275 15.8946 7.61711 16 7.88232 16ZM17.8823 4H13.8823V3C13.8823 2.20435 13.5663 1.44129 13.0036 0.87868C12.441 0.316071 11.678 0 10.8823 0H8.88232C8.08667 0 7.32361 0.316071 6.761 0.87868C6.19839 1.44129 5.88232 2.20435 5.88232 3V4H1.88232C1.61711 4 1.36275 4.10536 1.17522 4.29289C0.987681 4.48043 0.882324 4.73478 0.882324 5C0.882324 5.26522 0.987681 5.51957 1.17522 5.70711C1.36275 5.89464 1.61711 6 1.88232 6H2.88232V17C2.88232 17.7956 3.19839 18.5587 3.761 19.1213C4.32361 19.6839 5.08667 20 5.88232 20H13.8823C14.678 20 15.441 19.6839 16.0036 19.1213C16.5663 18.5587 16.8823 17.7956 16.8823 17V6H17.8823C18.1475 6 18.4019 5.89464 18.5894 5.70711C18.777 5.51957 18.8823 5.26522 18.8823 5C18.8823 4.73478 18.777 4.48043 18.5894 4.29289C18.4019 4.10536 18.1475 4 17.8823 4ZM7.88232 3C7.88232 2.73478 7.98768 2.48043 8.17522 2.29289C8.36275 2.10536 8.61711 2 8.88232 2H10.8823C11.1475 2 11.4019 2.10536 11.5894 2.29289C11.777 2.48043 11.8823 2.73478 11.8823 3V4H7.88232V3ZM14.8823 17C14.8823 17.2652 14.777 17.5196 14.5894 17.7071C14.4019 17.8946 14.1475 18 13.8823 18H5.88232C5.61711 18 5.36275 17.8946 5.17522 17.7071C4.98768 17.5196 4.88232 17.2652 4.88232 17V6H14.8823V17ZM11.8823 16C12.1475 16 12.4019 15.8946 12.5894 15.7071C12.777 15.5196 12.8823 15.2652 12.8823 15V9C12.8823 8.73478 12.777 8.48043 12.5894 8.29289C12.4019 8.10536 12.1475 8 11.8823 8C11.6171 8 11.3628 8.10536 11.1752 8.29289C10.9877 8.48043 10.8823 8.73478 10.8823 9V15C10.8823 15.2652 10.9877 15.5196 11.1752 15.7071C11.3628 15.8946 11.6171 16 11.8823 16Z"
                  fill="#878b9d" />
          </svg>`,
          content:
            'Vill du ta bort hela ansökan och dess information, från tjänsten helt och hållet?',
          cta: 'Vill du radera ansökan?',
          type: 'REMOVE'
        }
      ],
      activeCurrentTenantIdx: 0,
      // activeSecondaryTenantIdx: 0,
      selectedPartInfo: null,
      selectedApplicantId: null,
      selectedDocumentType: null
      //validDate
    };
  },
  computed: {
    landlordUsersWithUnAssign() {
      // Add "unassign" button if there is an assigned landlordUser
      if (
        this.application.assignedLandlordUserId &&
        this.landlordUsers[0].id !== null
      ) {
        return [
          {
            firstName: 'Ej',
            lastName: 'Tilldelad',
            id: null
          },
          ...this.landlordUsers
        ];
      }
      return this.landlordUsers;
    },
    secondaryTenants() {
      return this.application.secondaryTenants;
    },
    currentTenants() {
      return this.application.currentTenants;
    },
    isRemoved() {
      return this.application.status === statusTypes.REMOVED;
    },
    isPendingRemoval() {
      return this.application.status === statusTypes.PENDING_REMOVE;
    },
    hasRemovalTypeStatus() {
      return this.isRemoved || this.isPendingRemoval;
    },
    activeCurrentTenant() {
      return this.application.currentTenants[this.activeCurrentTenantIdx] || {};
    },
    // activeSecondaryTenant() {
    //   return (
    //     this.application.secondaryTenants[this.activeSecondaryTenantIdx] || {}
    //   );
    // },
    currentApplicationId() {
      const { id } = this.$route.params;
      return id;
    },
    activeTab() {
      return this.$route.query.tab ? parseInt(this.$route.query.tab) : 1;
    },
    tabHeaderTitle() {
      return reasonText(this.application.reason);
    },
    tabHeaderSubtitle() {
      if (this.application.landlordStatus === statusTypes.APPROVED) {
        return `(godkänd)`;
      }
      if (this.application.landlordStatus === statusTypes.REJECTED) {
        return `(nekad)`;
      }
      if (this.application.landlordStatus === statusTypes.CLOSED) {
        return `(avslutad)`;
      }
      if (this.application.landlordStatus === statusTypes.PENDING_REMOVE) {
        return `(kommer bli borttagen)`;
      }
      if (this.application.landlordStatus === statusTypes.REMOVED) {
        return `(borttagen)`;
      }
      return '';
    },
    isIncoming() {
      return this.application.landlordStatus === statusTypes.INCOMING;
    },
    isApproved() {
      return this.application.landlordStatus === statusTypes.APPROVED;
    },
    isRejected() {
      return this.application.landlordStatus === statusTypes.REJECTED;
    },
    getApartmentAdress() {
      let arr = [];
      const apartment = this.application.apartment;
      if (apartment.address) {
        arr.push(apartment.address);
      }
      if (apartment.city) {
        arr.push(apartment.city);
      }

      return arr.join(', ');
    },
    otherCheckboxes() {
      const { rentFrom, rentTo } = this.application.reason;
      return [
        {
          category: 'onskat_datum',
          value:
            validDate(rentFrom) && validDate(rentFrom)
              ? `${new Date(rentFrom).toLocaleDateString(
                  'sv-SE'
                )} till ${new Date(rentTo).toLocaleDateString('sv-SE')}`
              : '-'
        }
      ];
    },
    reasonText() {
      return reasonText(this.application.reason);
    },
    housingTypeTitle() {
      if (this.application.reason.housingType === 'hyresratt') {
        return 'Hyresrätt';
      } else if (this.application.reason.housingType === 'bostadsratt') {
        return 'Bostadsrätt';
      } else {
        return 'Villa';
      }
    }
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getSubletApplication();
      await this.getFiles(this.currentApplicationId);
      // const documents = await this.$http.getDocuments(
      //   this.currentApplicationId
      // );
      // for (const part of this.application.parts) {
      //   if (!documents[part.id]) {
      //     documents[part.id] = null;
      //   }
      // }

      // this.documentsCollections = documents;
      this.landlordUsers = await this.$http.getLandlordUsers();
    } catch {
      this.showErrorModal();
    }
    this.isLoading = false;
  },
  methods: {
    ...mapMutations({
      setAhApplication: 'applications/setAhApplication'
    }),
    ...mapActions({
      getFiles: 'landlordFiles/getFiles'
    }),
    async assignLandlordUser({ firstName, id, lastName }) {
      if (id === null) {
        this.unassignLandlordUser();
        return;
      }

      this.application.assignedLandlordUserId = id;
      this.application.assignedLandlordFirstName = firstName;
      this.application.assignedLandlordLastName = lastName;

      await this.$http.assignLandlordUser(this.currentApplicationId, id, true);
      // churnService.track(events.UPDATE_MANAGER, features.UPDATE_MANAGER.ASSIGN);

      this.setAhApplication({
        id: this.currentApplicationId,
        assignedLandlordUserId: id,
        assignedLandlordFirstName: firstName,
        assignedLandlordLastName: lastName
      });
    },

    async unassignLandlordUser() {
      this.application.assignedLandlordUserId = null;
      this.application.assignedLandlordFirstName = null;
      this.application.assignedLandlordLastName = null;

      await this.$http.unassignLandlordUser(this.currentApplicationId, true);
      // churnService.track(
      //   events.UPDATE_MANAGER,
      //   features.UPDATE_MANAGER.UNASSIGN
      // );

      this.setAhApplication({
        id: this.currentApplicationId,
        assignedLandlordUserId: null,
        assignedLandlordFirstName: null,
        assignedLandlordLastName: null
      });
    },
    leaveApplication() {
      this.$router.push({ path: '/andrahand/alla' });
    },
    async updateCheckedMember({ checked, category }) {
      try {
        if (checked) {
          await this.$http.addCheck(this.application.id, 0, category);
          const addedChecks = [...this.application.checkedFiles, category];

          this.$set(this.application, 'checkedFiles', addedChecks);
        } else {
          await this.$http.removeCheck(this.application.id, 0, category);
          const removedChecks = this.application.checkedFiles.filter(
            check => check !== category
          );

          this.$set(this.application, 'checkedFiles', removedChecks);
        }
      } catch (error) {
        console.error(error);
      }
    },
    openDocumentModal({ index, category }) {
      if (this.isIncoming || this.isRejected) {
        return;
      } else {
        this.selectedApplicantId = `${index}`;
        this.selectedDocumentType = category;
        this.currentModal = 'DOCUMENTS';
      }
    },

    openPartInfoModal({ x, y, type, index }) {
      this.selectedPartInfoModalPosition = {
        x,
        y
      };

      switch (type) {
        case 'currentTenants':
          console.log(
            'dansa runt',
            this.currentTenants,
            index,
            this.currentTenants[index]
          );
          this.selectedPartInfo = [
            {
              ...this.currentTenants[index],
              address: this.application.apartment.address,
              city: this.application.apartment.city,
              isRenter: index === 0
            }
          ];
          break;
        case 'secondaryTenants':
          this.selectedPartInfo = [
            {
              ...this.secondaryTenants[index],
              city: ''
            }
          ];
          break;
        default:
          this.selectedPartInfo = null;
      }
    },
    showErrorModal() {
      this.errorModalVisible = true;
    },
    handleEditNote({ editedText, noteId }) {
      const noteIndex = this.application.landlordNotes.findIndex(
        n => n.id === noteId
      );

      if (noteIndex !== -1) {
        this.$set(this.application.landlordNotes, noteIndex, {
          ...this.application.landlordNotes[noteIndex],
          text: editedText,
          editedAt: true
        });
      }
    },
    handleDeleteNote(noteId) {
      const noteIndex = this.application.landlordNotes.findIndex(
        note => note.id === noteId
      );
      if (noteIndex !== -1) {
        this.$set(this.application.landlordNotes, noteIndex, {
          ...this.application.landlordNotes[noteIndex],
          deletedAt: true
        });
      }
    },
    handleAddNote(note) {
      this.application.landlordNotes = [
        ...(this.application.landlordNotes || []),
        note
      ];
    },
    async getSubletApplication() {
      const res = await this.$http.getSubletApplication(
        this.currentApplicationId
      );
      this.application = res;
    },
    setTab(tabNumber) {
      this.$router.push({ query: { tab: tabNumber } });
    },
    handleEscClick() {
      if (!this.currentModal) {
        this.$emit('close');
      }
    },
    handlePopupClick(type) {
      switch (type) {
        case 'REMOVE':
          if (this.currentUserIsAssigned) {
            this.currentModal = 'REMOVE';
          } else {
            this.currentModal = 'USER_NOT_ASSIGNED';
          }
          break;
        case 'ABORT':
          console.log('Not implemented: Show ABORT modal');
          break;
        default:
          this.currentModal = null;
      }
      this.popupModalVisible = false;
    }
  }
};
</script>

<style scoped>
.name {
  color: var(--color-dark-blue);
  font-weight: 600;
  font-size: 1.8rem;
}
.name-small {
  color: var(--color-dark-blue);
}
h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0.5rem;
}
.apartment-info li {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
}
.apartment-info li b {
  font-weight: 600;
}

.divider {
  background-color: #e4e5e8;
  margin: 2rem 0;
  border: none;
  height: 1px;
}

.base-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  overflow: hidden;
}
.body {
  position: relative;
  background: #fff;
  padding: 25px 35px 50px 35px;
  width: 100%;
  overflow: auto;
  max-height: 70vh;
}
.body > :deep(.applications-row) {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}
.body--animating {
  overflow: hidden;
}
.body--animating > :deep(.applications-row) {
  opacity: 0;
  transition: none;
}
.footer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  padding: 22px 35px 22px 35px;
  background: #f1f2f6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.footer > .left {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
}
::v-deep .left .text {
  margin-left: 7px;
}
.footer > .left > * {
  margin-right: 25px;
}
.mr-15 {
  margin-right: 15px;
}
.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.pdf-link {
  margin-top: 30px;
  font-size: 12px;
  text-decoration: underline;
}
.assign-button,
.notes-button,
.files-button {
  font-weight: 600;
  font-size: 1.2rem;
}
.assign-button {
  color: var(--color-dark-blue);
  font-weight: 700;
}
.popupModal-container {
  position: relative;
  vertical-align: text-bottom;
  display: inline-block;
}
</style>
