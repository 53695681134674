import http from '@/services/http';

async function getTags({ rootState, commit }) {
  const type = rootState.app.activeApp === 'bytesansokan' ? 'ba' : 'ah';
  const tags = await http.getAvailableTags(type);
  commit('setTags', tags);
  commit('applications/setFilterTags', tags, { root: true });
}

async function addTag({ state, rootState, commit }, tagId) {
  await http.addTag(state.tagsActiveApplicationId, tagId);
  if (rootState.app.activeApp === 'bytesansokan') {
    commit(
      'applications/addApplicationTag',
      { applicationId: state.tagsActiveApplicationId, tagId },
      { root: true }
    );
  } else if (rootState.app.activeApp === 'andrahand') {
    commit(
      'applications/addAhApplicationTag',
      { applicationId: state.tagsActiveApplicationId, tagId },
      { root: true }
    );
  }
}

async function removeTag({ state, rootState, commit }, tagId) {
  await http.removeTag(state.tagsActiveApplicationId, tagId);
  if (rootState.app.activeApp === 'bytesansokan') {
    commit(
      'applications/removeApplicationTag',
      { applicationId: state.tagsActiveApplicationId, tagId },
      { root: true }
    );
  } else if (rootState.app.activeApp === 'andrahand') {
    commit(
      'applications/removeAhApplicationTag',
      { applicationId: state.tagsActiveApplicationId, tagId },
      { root: true }
    );
  }
}

export default {
  getTags,
  addTag,
  removeTag
};
