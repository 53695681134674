<template>
  <div class="select-wrapper" :class="{ hidden: hidden }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    hidden: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.select-wrapper {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: inline-block;
}

.select-wrapper.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

select {
  font-family: var(--font-averta);
  cursor: pointer;
  background-color: transparent;
  color: var(--color-grey);
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 600;
  border: none;
  position: relative;
  outline: 0;
  width: 100%;
  padding: 0.4rem 0rem 0.4rem 2.1rem;
  appearance: none;
  background: url('../assets/svgs/icons/chevron-down.svg') no-repeat left center;
  background-size: 2rem;
}
</style>
