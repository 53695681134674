function setTags(state, tags) {
  state.tags = tags;
}

function toggleTagModal(state, applicationId) {
  state.tagsActiveApplicationId = applicationId;
}

export default {
  setTags,
  toggleTagModal
};
