<template>
  <DefaultPage>
    <h1 class="page-title">Support</h1>
    <div class="content">
      <div class="card">
        <h4>Kontakt</h4>
        <a href="mailto:support@bytesansokan.se" class="email">
          <BaseIconText :icon-url="svgs.ICONS.ENVELOPE">
            support@bytesansokan.se
          </BaseIconText>
        </a>
      </div>
    </div>
  </DefaultPage>
</template>
<script>
import BaseIconText from '@/components/BaseIconText.vue';
import DefaultPage from '@/components/DefaultPage.vue';
import svgs from '@/utils/icon-constants';

export default {
  components: {
    BaseIconText,
    DefaultPage
  },

  data() {
    return {
      svgs
    };
  }
};
</script>
<style scoped>
.content {
  margin-left: 4rem;
  max-width: 100%;
  width: 1200px;
  padding-bottom: 20rem;
}

.page-title {
  color: var(--color-dark-blue);
  font-size: 3.2rem;
  margin: 3.2rem 3.2rem 3.2rem 4rem;
  height: 4rem;
  padding-bottom: 2rem;
}

.card {
  max-width: 100%;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  color: #2c3e50;
}

.card h4 {
  font-size: 2.8rem;
  margin: 0 0 1.5rem;
}

.email {
  color: var(--color-dark-blue);
}
</style>
