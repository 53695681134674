import { render, staticRenderFns } from "./SubletApprovalModal.vue?vue&type=template&id=64a38ae8&scoped=true"
import script from "./SubletApprovalModal.vue?vue&type=script&lang=js"
export * from "./SubletApprovalModal.vue?vue&type=script&lang=js"
import style0 from "./SubletApprovalModal.vue?vue&type=style&index=0&id=64a38ae8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a38ae8",
  null
  
)

export default component.exports