var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tooltip-container"},[_vm._t("default"),(_vm.tooltipExists)?_c('div',{ref:"tooltip",class:[
      'tooltip',
      { 'show-tooltip': _vm.showTooltip },
      { top: _vm.position === 'top' },
      { bottom: _vm.position === 'bottom' },
      { 'bottom-left': _vm.position === 'bottom-left' },
      { right: _vm.position === 'right' }
    ]},[_c('div',{staticClass:"tooltip-header"},[_c('BaseImage',{staticClass:"tooltip-header-image",attrs:{"url":_vm.svgs.ICONS.TIP}}),_vm._l((_vm.getCurrentTooltipTitle()),function(t,idx){return _c('p',{key:idx},[_vm._v(_vm._s(t))])})],2),_vm._l((_vm.getCurrentTooltip()),function(c,idx){return _c('p',{key:idx},[_vm._v(" "+_vm._s(c)+" ")])}),_c('div',{staticClass:"tooltip-footer"},[_c('BaseButton',{staticClass:"next-tip-btn",attrs:{"is-rounded":true,"is-small":true,"is-default":true,"type":"submit"},on:{"click":function($event){return _vm.handleMarkTooltipAsSeen({ reference: _vm.step })}}},[_c('span',{staticClass:"tooltip-button-text"},[_vm._v(_vm._s(_vm.btnText))]),_c('BaseIconText',{staticClass:"invert",attrs:{"icon-url":_vm.showArrow ? _vm.svgs.ICONS.ARROW_RIGHT : ''}})],1)],1)],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }