import http from '@/services/http';
import router from '@/router';
import { getLocalStorage } from '@/utils/windowUtils';
import churnService from '@/utils/churn360';
import tutorialService from '@/services/tutorialService';
import { APP_NAMES } from '@/utils/constants';

// const mobileMediaQuery = window.matchMedia('(max-width: 768px)');
// const smallDesktopQuery = window.matchMedia(
//   '(min-width: 769px) and (max-width: 1280px)'
// );

async function initiate(ctx) {
  // ctx.dispatch('getAndsetScreenSize');
  const LAST_ACTIVE_APP =
    getLocalStorage('BA_ACTIVE_APP') || APP_NAMES.BYTESANSOKAN;

  const ahRoute = router.currentRoute.meta?.ahRoute || false;
  const baRoute = router.currentRoute.meta?.baRoute || false;

  const path = window.location.pathname;
  const account = await http.getAccount();
  const isAuth = account !== null;

  ctx.commit('setIsAuthenticated', isAuth);
  ctx.commit('setAccount', account);

  if (path.includes('logga-ut')) {
    // do nothing
  } else if (isAuth) {
    if (path.includes('logga-in')) {
      router.push('/');
    } else {
      if (ahRoute) {
        if (!ctx.getters.ahAccess) {
          // No Andrahand access, fallback to Bytesansökan
          await ctx.dispatch('updateActiveApp', APP_NAMES.BYTESANSOKAN);
          router.push('/');
        } else {
          // Andrahand route and has access
          await ctx.dispatch('updateActiveApp', APP_NAMES.ANDRAHAND);
        }
      } else if (baRoute) {
        if (!ctx.getters.baAccess) {
          // No Bytesansökan access, fallback to Andrahand
          await ctx.dispatch('updateActiveApp', APP_NAMES.ANDRAHAND);
          router.push('/');
        } else {
          // Bytesansökan route and has access
          await ctx.dispatch('updateActiveApp', APP_NAMES.BYTESANSOKAN);
        }
      } else {
        // No specific Andrahand/Bytesansökan route, revert to last active
        await ctx.dispatch('updateActiveApp', LAST_ACTIVE_APP);
      }
    }
    await ctx.dispatch('syncTutorialSteps');

    // try {
    //   await ctx.dispatch('initChurn360');
    // } catch {
    //   console.error('Failed to init churn');
    // }
  }
  ctx.commit('setIsReady', true);
}

async function logout() {
  await http.logout();
  window.location.replace('/logga-in');
}

// async function getAndsetScreenSize({ state, commit }) {
//   let newSize = 'DESKTOP';

//   if (mobileMediaQuery.matches) {
//     newSize = 'MOBILE';
//   } else if (smallDesktopQuery.matches) {
//     newSize = 'DESKTOP_SMALL';
//   }

//   if (state.setScreenSize !== newSize) {
//     commit('setScreenSize', newSize);
//     window.dispatchEvent(new CustomEvent('new-size', { detail: newSize }));
//   }
// }

async function syncTutorialSteps(ctx) {
  let seenTutorials = await tutorialService.getUserTutorialsSeen();

  if (!seenTutorials) {
    return;
  }

  // We clear seen tutorials on seen to enable correct counting when
  // new tutorials are present (1 of 1 on new visits instead of 3 of 3)
  for (let tutorialId of seenTutorials) {
    ctx.commit('markSyncedTooltipAsSeen', tutorialId);
  }
}

const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

async function initChurn360(ctx) {
  if (location.host.includes('localhost')) {
    return;
  }
  const { account } = ctx.state;
  if (!validateEmail(account.email)) {
    return;
  }
  await churnService.loadScript();
  await churnService.initialize(account.landlordId, account.email);
  await churnService.identify(
    {
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email
    },
    account.userId
  );
}

async function markTooltipAsSeen(ctx, { reference }) {
  if (!reference) {
    return;
  }

  ctx.commit('markTooltipAsSeen', reference);

  await tutorialService.addSeenTutorialStep(reference);
}

async function resetFirstPartOfTooltipsArray(ctx) {
  ctx.commit('resetFirstPartOfTooltipsArray');
}

async function resetSecondPartOfTooltipsArray(ctx) {
  ctx.commit('resetSecondPartOfTooltipsArray');
}

async function updateActiveApp(ctx, name) {
  ctx.commit('setActiveApp', name);
  localStorage.setItem('BA_ACTIVE_APP', name);
  await ctx.dispatch('tags/getTags', null, {
    root: true
  });
}

export default {
  initiate,
  logout,
  syncTutorialSteps,
  markTooltipAsSeen,
  resetFirstPartOfTooltipsArray,
  resetSecondPartOfTooltipsArray,
  initChurn360,
  updateActiveApp
};
