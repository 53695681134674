import { render, staticRenderFns } from "./SubletNotesModal.vue?vue&type=template&id=64b80d31&scoped=true"
import script from "./SubletNotesModal.vue?vue&type=script&lang=js"
export * from "./SubletNotesModal.vue?vue&type=script&lang=js"
import style0 from "./SubletNotesModal.vue?vue&type=style&index=0&id=64b80d31&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b80d31",
  null
  
)

export default component.exports